import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTiktok } from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/logo.webp";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-6">
        <div className="flex justify-around flex-col sm:flex-row">
          {/* Company Info */}
          <div className="mb-6 md:mb-0">
            <img src={logo} alt="Logo" className="h-40" />
          </div>

          {/* Contact Info */}
          <div className="mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-4">Kontakt</h3>
            <p className="mb-2">KAD Bygg AB</p>
            <p className="mb-2">Västgötaresan 46, Uppsala, Sverige</p>
            <p className="mb-2">
              <a
                href="tel:+46735510725"
                className="hover:underline md:hover:no-underline lg:hover:underline hover:text-orange-400"
              >
                Tel: 073-551 07 25
              </a>
            </p>
            <p className="mb-2">
              <a
                href="mailto:info@kadbygg.se"
                className="hover:underline md:hover:no-underline lg:hover:underline hover:text-orange-400"
              >
                Email: info@kadbygg.se
              </a>
            </p>
          </div>

          {/* Navigation Links */}
          <div className="mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-4">Snabblänkar</h3>
            <ul>
              <li className="mb-2">
                <Link to="/" className="hover:text-orange-400">
                  Hem
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/services" className="hover:text-orange-400">
                  Tjänster
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/about" className="hover:text-orange-400">
                  Om Oss
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/contact" className="hover:text-orange-400">
                  Kontakt
                </Link>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div className="mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-4">Följ oss</h3>
            <div className="flex space-x-6">
              <a
                href="https://www.facebook.com/profile.php?id=100083087451078"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-orange-400 transition-colors duration-300"
              >
                <FontAwesomeIcon icon={faFacebookF} size="2x" />
              </a>
              <a
                href="https://www.tiktok.com/@kadbyggab?_t=8oztipQRBjc&_r=1"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-orange-400 transition-colors duration-300"
              >
                <FontAwesomeIcon icon={faTiktok} size="2x" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 border-t mx-4 md:mx-12 border-gray-600 pt-4 text-center text-sm">
        <p>
          &copy; {new Date().getFullYear()} Byggfirma AB. Alla rättigheter
          reserverade.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
