import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  return (
    <div>
      <Header />
      <main className={`transition-all ${isHomePage ? "pt-0" : "pt-[95px]"}`}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
