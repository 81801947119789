import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { services } from "../content/content";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ServiceCarousel = () => {
  const carouselRef = useRef(null);

  const scroll = (direction) => {
    const scrollAmount = direction === "left" ? -400 : 400;
    carouselRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative flex flex-col gap-6 py-16">
      <h2 className="text-2xl md:text-5xl font-bold text-center">
        Våra Tjänster
      </h2>
      <p className="text-lg text-black text-center p-2">
        Utforska vårt omfattande utbud av tjänster och se hur vi kan hjälpa dig.
      </p>
      {/* Carousel Container */}
      <div
        ref={carouselRef}
        className="px-4 flex space-x-4 overflow-x-auto scrollbar-hidden snap-x snap-mandatory"
      >
        {services.map((service) => (
          <Link
            key={service.path}
            to={service.path}
            className="rounded-lg my-4 flex-shrink-0 snap-center w-96 h-96 bg-cover bg-center text-white flex items-center justify-center relative transition-transform transform md:hover:scale-105 group"
            style={{ backgroundImage: `url(${service.images[0]})` }}
          >
            {/* Overlay */}
            <div className="rounded-lg absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-transform duration-300 md:group-hover:translate-y-0 md:group-hover:bg-opacity-65">
              <div className="flex flex-col items-center justify-center text-center transform md:group-hover:scale-90">
                {/* Service Name */}
                <h2 className="text-4xl font-bold mb-4 transition-transform duration-300 md:group-hover:hidden">
                  {service.name}
                </h2>
                {/* Description with "Click to know more" */}
                <p className="mb-2 text-xl font-semibold hidden md:group-hover:block transition-transform duration-300 transform md:group-hover:translate-y-0">
                  {service.shortDescription}
                </p>
                <p className="text-lg hidden md:group-hover:block transition-transform duration-300 transform md:group-hover:translate-y-0">
                  Klicka för att veta mer
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {/* Navigation Arrows */}
      <div className="flex w-full justify-center items-center gap-4">
        <button
          onClick={() => scroll("left")}
          className="bg-black text-white p-2 rounded-full shadow-lg hover:bg-gray-800 transition-colors duration-300 z-10"
        >
          <FaChevronLeft size={24} />
        </button>
        <button
          onClick={() => scroll("right")}
          className="bg-black text-white p-2 rounded-full shadow-lg hover:bg-gray-800 transition-colors duration-300 z-10"
        >
          <FaChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

export default ServiceCarousel;
