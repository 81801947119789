import React from "react";
import { Link } from "react-router-dom";
import { services } from "../content/content";
import backgroundImage from "../assets/headers/header1.webp";

const Services = () => {
  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <section className="relative h-96 flex items-center justify-center text-center text-white">
        <img
          src={backgroundImage}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover z-0"
        />
        <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
        <div className="relative z-20">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-4">
            Våra Tjänster
          </h1>
          <p className="text-lg md:text-xl max-w-3xl mx-auto px-2">
            Utforska vårt omfattande utbud av tjänster och se hur vi kan hjälpa
            dig.
          </p>
        </div>
      </section>

      {/* Services Grid Section */}
      <div className="my-6 md:my-0 p-2 md:p-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service) => (
          <Link
            key={service.name}
            to={service.path}
            className="rounded-lg bg-white text-black shadow-lg overflow-hidden transition-transform duration-300"
          >
            <div className="relative overflow-hidden">
              <img
                src={service.images[0]}
                alt={service.name}
                className="w-full h-52 object-cover transform transition-transform duration-300 hover:scale-110"
              />
            </div>
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-2">{service.name}</h2>
              <p className="text-gray-600 mb-4">{service.shortDescription}</p>
              <span className="text-orange-400 hover:underline">
                Learn more
              </span>
            </div>
          </Link>
        ))}
      </div>
      <section className="py-12 bg-gradient-to-r from-orange-600 to-orange-400 text-center text-white">
        <h2 className="text-3xl font-bold mb-8">Kontakta Oss</h2>
        <p className="text-lg leading-relaxed max-w-3xl mx-auto mb-8">
          Behöver du mer information eller är du redo att starta ditt projekt?
          Klicka på knappen nedan för att komma till vår kontaktsida.
        </p>
        <Link
          to="/contact"
          className="inline-block px-8 py-4 bg-white text-orange-600 font-bold rounded-lg hover:bg-gray-200 transition-colors duration-300"
        >
          Kontakta Oss
        </Link>
      </section>
    </div>
  );
};

export default Services;
