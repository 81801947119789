import React from "react";
import heroImg from "../assets/hero.webp";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div
      className="relative h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${heroImg})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="text-center text-white px-4">
          <h1 className="text-3xl md:text-6xl font-bold mb-4">
            Vi bygger med stolthet
          </h1>
          <p className="text-xl md:text-3xl mb-8">
            Erfarenhet. Kvalitet. Hållbarhet.
          </p>
          <Link
            to={"/contact"}
            className="rounded-lg bg-gradient-to-r from-orange-600 to-orange-500 text-white px-6 py-3 text-lg font-semibold  hover:from-orange-500 hover:to-orange-400 transition-colors duration-300"
          >
            Kontakta Oss
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
