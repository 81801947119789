import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCheckCircle,
  faHandshake,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import backgroundImage from "../assets/headers/header3.webp";

const About = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}

      <section className="relative  h-96 flex items-center justify-center text-center text-white">
        <img
          src={backgroundImage}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover z-0"
        />
        <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
        <div className="relative z-20">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-4">Om Oss</h1>
          <p className="text-lg md:text-xl max-w-3xl mx-auto">
            Vi är ett byggföretag som erbjuder högkvalitativa byggtjänster med
            ett personligt engagemang. Vår passion för byggande och vår
            erfarenhet gör oss till det rätta valet för ditt projekt.
          </p>
        </div>
      </section>

      {/* <section className="py-12 bg-gray-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Vårt Team
          </h2>
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <img
                  src="https://randomuser.me/api/portraits/men/1.jpg"
                  alt="Team Member"
                  className="w-32 h-32 rounded-full mx-auto mb-4 border-4 border-gray-200"
                />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  Dede Gjonaji
                </h3>
                <p className="text-gray-600">Byggprojektledare</p>
                <p className="text-gray-500 mt-2">
                  John är en erfaren byggprojektledare med över 15 års
                  erfarenhet inom branschen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Company History Section */}
      <section className="text-center bg-gradient-to-r from-orange-600 to-orange-400 py-12">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-white mb-6 text-center">
            Vår Historia
          </h2>
          <p className="text-lg text-white text-center max-w-3xl mx-auto">
            Vi på KAD Bygg AB brinner för att skapa högkvalitativa snickeri- och
            renoveringslösningar. Med vår erfarenhet och passion för hantverk
            hjälper vi dig att förverkliga dina byggdrömmar, särskilt när det
            gäller köksrenoveringar. Vi är ett litet, dedikerat team som alltid
            sätter dig som kund i första rummet. Vår noggrannhet och engagemang
            har byggt vårt starka rykte, och vi ser fram emot att fortsätta
            skapa värde tillsammans med dig.
          </p>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-12 bg-gray-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Våra Värderingar
          </h2>
          <div className="flex flex-wrap justify-center">
            {/* Value Item */}
            <div className="w-full md:w-1/2 lg:w-1/4 p-4 ">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col items-center justify-center h-64">
                <FontAwesomeIcon
                  icon={faStar}
                  size="3x"
                  className="text-orange-500 mb-4"
                />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  Kvalitet
                </h3>
                <p className="text-gray-600">
                  Vi strävar alltid efter att leverera det bästa möjliga
                  resultatet.
                </p>
              </div>
            </div>
            {/* Another Value Item */}
            <div className="w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col items-center justify-center h-64">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="3x"
                  className="text-green-500 mb-4"
                />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  Pålitlighet
                </h3>
                <p className="text-gray-600">
                  Du kan lita på oss att vi håller våra löften och levererar i
                  tid.
                </p>
              </div>
            </div>
            {/* Another Value Item */}
            <div className="w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col items-center justify-center h-64">
                <FontAwesomeIcon
                  icon={faHandshake}
                  size="3x"
                  className="text-blue-500 mb-4"
                />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  Engagemang
                </h3>
                <p className="text-gray-600">
                  Vi är dedikerade till varje projekt och arbetar hårt för att
                  överträffa dina förväntningar.
                </p>
              </div>
            </div>
            {/* Another Value Item */}
            <div className="w-full md:w-1/2 lg:w-1/4 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center flex flex-col items-center justify-center h-64">
                <FontAwesomeIcon
                  icon={faLeaf}
                  size="3x"
                  className="text-green-700 mb-4"
                />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  Hållbarhet
                </h3>
                <p className="text-gray-600">
                  Vi använder hållbara material och metoder för att minimera vår
                  miljöpåverkan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-12 bg-gradient-to-r from-orange-600 to-orange-400 text-center text-white">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-8">Vill du veta mer om oss?</h2>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
            <Link
              to="/services"
              className="inline-block px-8 py-4 bg-white text-orange-600 font-bold rounded-lg hover:bg-gray-200 transition-colors duration-300"
            >
              Våra Tjänster
            </Link>
            <Link
              to="/contact"
              className="inline-block px-8 py-4 bg-white text-orange-600 font-bold rounded-lg hover:bg-gray-200 transition-colors duration-300"
            >
              Kontakta Oss
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
