// src/components/IntroductionSection.js
import React from "react";
import { Link } from "react-router-dom";

const IntroductionSection = () => {
  return (
    <section className="py-12 bg-gradient-to-r from-orange-600 to-orange-400">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-4 text-white">
          Välkommen till KAD Bygg AB
        </h2>
        <p className="text-lg mb-2 text-white">
          Vi erbjuder förstklassiga bygg- och renoveringstjänster som
          överträffar dina förväntningar.
        </p>
        <p className="text-lg mb-4 text-white">
          Vårt erfarna team levererar kvalitet med fokus på detaljer. Utforska
          våra tjänster och se hur vi kan förverkliga ditt nästa projekt.
        </p>
        <Link
          to="/about"
          className="text-gray-100 hover:underline font-semibold "
        >
          Läs mer om oss
        </Link>
      </div>
    </section>
  );
};

export default IntroductionSection;
