import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import backgroundImage from "../assets/headers/header3.webp";

const Contact = () => {
  return (
    <div className="bg-gray-50 min-h-screen flex flex-col justify-between">
      {/* Hero Section */}

      <section className="relative h-96 flex items-center justify-center text-center text-white">
        <img
          src={backgroundImage}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover z-0"
        />
        <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
        <div className="relative z-20">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-4">
            Kontakta Oss
          </h1>
          <p className="text-lg md:text-xl max-w-3xl mx-auto px-2">
            Har du frågor eller behöver du hjälp? Här är våra kontaktuppgifter
            för att nå oss snabbt och enkelt.
          </p>
        </div>
      </section>

      {/* Contact Information Section */}
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-6">
          <h2 className="text-2xl md:text-4xl font-extrabold text-gray-800 mb-8 text-center">
            Kontakta Oss Direkt
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Phone */}
            <a
              href="tel:+46735510725"
              className="bg-white p-6 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:shadow-xl hover:bg-gray-100"
            >
              <div className="mb-4">
                <FaPhoneAlt className="w-12 h-12 text-orange-400 mx-auto" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                Telefon
              </h3>
              <p className="text-lg text-gray-600">073-551 07 25</p>
            </a>

            {/* Email */}
            <a
              href="mailto:info@byggfirma.se"
              className="bg-white p-6 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:shadow-xl hover:bg-gray-100"
            >
              <div className="mb-4">
                <FaEnvelope className="w-12 h-12 text-orange-400 mx-auto" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                E-post
              </h3>
              <p className="text-lg text-gray-600">info@kadbygg.se</p>
            </a>

            {/* Address */}
            <div className="bg-white p-6 rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:shadow-xl hover:bg-gray-100">
              <div className="mb-4">
                <FaMapMarkerAlt className="w-12 h-12 text-orange-400 mx-auto" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                Adress
              </h3>
              <p className="text-lg text-gray-600">
                Västgötaresan 46, Uppsala, Sverige
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-orange-600 to-orange-400 py-12 px-2 text-center text-white">
        <h2 className="text-3xl font-bold mb-4">
          Vi Ser Fram Emot Att Höra Från Dig!
        </h2>
        <p className="text-lg mb-6">
          Om du har några frågor eller behöver mer information, tveka inte att
          kontakta oss. Vi är här för att hjälpa dig!
        </p>
        <a
          href="mailto:info@byggfirma.se"
          className="bg-white text-orange-600 py-3 px-6 rounded-lg text-xl font-semibold hover:bg-gray-200 transition-colors duration-300"
        >
          Skicka E-post
        </a>
      </section>
    </div>
  );
};

export default Contact;
