import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { navLinks, services } from "../../content/content";
import logo from "../../assets/logo1.webp";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [hoverTimer, setHoverTimer] = useState(null);
  const [isMobileServicesOpen, setIsMobileServicesOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) setIsMobileServicesOpen(false);
  };

  const handleMouseEnter = () => {
    if (hoverTimer) clearTimeout(hoverTimer);
    setIsServicesOpen(true);
  };

  const handleMouseLeave = () => {
    const timer = setTimeout(() => setIsServicesOpen(false), 300);
    setHoverTimer(timer);
  };

  const handleMobileServicesClick = () => {
    setIsMobileServicesOpen(!isMobileServicesOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsMobileServicesOpen(false);
  };

  const isHomePage = location.pathname === "/";

  return (
    <header
      className={`fixed w-full top-0 left-0 z-50 transition-colors duration-300 ${
        isScrolled || !isHomePage ? "bg-black" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-6 py-4 flex justify-between items-start ">
        <div className="flex-shrink-0 transition-all duration-300">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className={`transition-all duration-300 ${
                isHomePage && !isScrolled ? "h-16 md:h-40" : "h-16 md:h-16"
              }`}
            />
          </Link>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center space-x-6 text-white pt-4">
          {navLinks.map((link) => (
            <div
              key={link.name}
              className="relative"
              onMouseEnter={link.hasDropdown ? handleMouseEnter : null}
              onMouseLeave={link.hasDropdown ? handleMouseLeave : null}
            >
              <Link
                to={link.path}
                className="hover:text-orange-400 transition-colors duration-300 font-semibold text-lg"
                onClick={link.hasDropdown ? handleMobileServicesClick : null}
              >
                {link.name}
              </Link>
              {link.hasDropdown && isServicesOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-black text-white shadow-lg z-50">
                  {services.map((service) => (
                    <Link
                      key={service.path}
                      to={service.path}
                      className="block px-4 py-2 hover:bg-orange-400 transition-colors duration-300"
                    >
                      {service.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>

        {/* Mobile Menu Button */}
        <div className="md:hidden pt-4">
          <button onClick={toggleMenu} className="text-white">
            {isMenuOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <nav className="fixed inset-0 bg-black text-white flex flex-col items-center justify-center space-y-6 md:hidden">
            <button
              onClick={toggleMenu}
              className="absolute top-4 right-4 text-white"
            >
              <FaTimes size={24} />
            </button>
            {navLinks.map((link) => (
              <div key={link.name} className="relative">
                <Link
                  to={link.path}
                  className="text-2xl font-semibold hover:text-orange-400 transition-colors duration-300"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  {link.name}
                </Link>
                {link.hasDropdown && isMobileServicesOpen && (
                  <div className="absolute left-0 mt-2 w-full bg-black text-white shadow-lg z-50">
                    {services.map((service) => (
                      <Link
                        key={service.path}
                        to={service.path}
                        className="block px-4 py-2 hover:bg-orange-400 transition-colors duration-300"
                        onClick={() => closeMenu()}
                      >
                        {service.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
