import React from "react";
import { useParams, Link } from "react-router-dom";
import { services } from "../content/content";
import backgroundImage from "../assets/headers/header2.webp";

const Service = () => {
  const { serviceName } = useParams();

  const service = services.find((s) => s.path.split("/").pop() === serviceName);

  if (!service) {
    return (
      <h2 className="text-3xl font-bold text-center mt-10">
        Tjänsten hittades inte
      </h2>
    );
  }

  return (
    <div className="mx-auto bg-gray-100">
      {/* Service Header Section */}

      <section className="relative h-96 flex items-center justify-center text-center text-white">
        <img
          src={backgroundImage}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover z-0"
        />
        <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
        <div className="relative z-20">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-4">
            {service.name}
          </h1>
          <p className="text-lg md:text-xl max-w-3xl mx-auto px-2">
            {service.longDescription ||
              `Vi erbjuder professionell ${service.name.toLowerCase()} med hög kvalitet och pålitlighet.`}
          </p>
        </div>
      </section>

      {/* Image Gallery Section */}
      <section className="p-2 md:p-12 mt-6 md:mt-0">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {service.images.map((image, index) => (
            <div
              key={index}
              className="w-full h-96 bg-cover bg-center rounded-lg shadow-md"
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          ))}
        </div>
      </section>
      <div className="flex items-center justify-center p-12 md:pb-12 md:pt-0">
        <Link
          to="/services"
          className="inline-block  text-orange-400 font-semibold  hover:text-orange-600 hover:underline transition-colors duration-300"
        >
          Se alla tjänster
        </Link>
      </div>

      {/* More Info Section */}
      <section className="bg-gradient-to-r from-orange-600 to-orange-400 py-12 text-center text-white px-2">
        <h2 className="text-3xl font-bold mb-8 text-center">Mer Information</h2>
        <p className="text-lg leading-relaxed max-w-4xl mx-auto">
          {service.moreInfo ||
            `Kontakta oss för mer information om vår ${service.name.toLowerCase()} tjänst.`}
        </p>
      </section>

      {/* Contact Us Section */}
      <section className="py-12 px-2 text-center">
        <h2 className="text-3xl font-bold mb-8">Kontakta Oss</h2>
        <p className="text-lg leading-relaxed max-w-3xl mx-auto mb-8">
          Behöver du mer information eller är du redo att starta ditt projekt?
          Klicka på knappen nedan för att komma till vår kontaktsida.
        </p>
        <Link
          to="/contact"
          className="inline-block px-8 py-4 bg-orange-600  text-white font-bold rounded-lg hover:bg-orange-700 transition-colors duration-300"
        >
          Kontakta Oss
        </Link>
      </section>
    </div>
  );
};

export default Service;
