import gibs1 from "../assets/gibs/gibs1.webp";
import gibs2 from "../assets/gibs/gibs2.webp";
import gibs3 from "../assets/gibs/gibs3.webp";
import gibs4 from "../assets/gibs/gibs4.webp";
import gibs5 from "../assets/gibs/gibs5.webp";
import köksmontering1 from "../assets/köksmontering/köksmontering1.webp";
import köksmontering2 from "../assets/köksmontering/köksmontering2.webp";
import köksmontering3 from "../assets/köksmontering/köksmontering3.webp";
import köksmontering4 from "../assets/köksmontering/köksmontering4.webp";
import köksmontering5 from "../assets/köksmontering/köksmontering5.webp";
import tak1 from "../assets/tak/tak1.webp";
import tak2 from "../assets/tak/tak2.webp";
import tak3 from "../assets/tak/tak3.webp";
import tak4 from "../assets/tak/tak4.webp";
import tak5 from "../assets/tak/tak5.webp";
import tak6 from "../assets/tak/tak6.webp";
import golvläggning1 from "../assets/golvläggning/golvläggning1.webp";
import golvläggning2 from "../assets/golvläggning/golvläggning2.webp";
import golvläggning3 from "../assets/golvläggning/golvläggning3.webp";
import golvläggning4 from "../assets/golvläggning/golvläggning4.webp";
import fasad1 from "../assets/fasad/fasad1.webp";
import fasad2 from "../assets/fasad/fasad2.webp";
import fasad3 from "../assets/fasad/fasad3.webp";
import fasad4 from "../assets/fasad/fasad4.webp";
import fasad6 from "../assets/fasad/fasad6.webp";
import byggkompletering1 from "../assets/byggkompletering/byggkompletering1.webp";
import byggkompletering2 from "../assets/byggkompletering/byggkompletering2.webp";
import byggkompletering3 from "../assets/byggkompletering/byggkompletering3.webp";
import byggkompletering4 from "../assets/byggkompletering/byggkompletering4.webp";
import byggkompletering5 from "../assets/byggkompletering/byggkompletering5.webp";
import byggkompletering6 from "../assets/byggkompletering/byggkompletering6.webp";
import byggkompletering7 from "../assets/byggkompletering/byggkompletering7.webp";
import byggkompletering8 from "../assets/byggkompletering/byggkompletering8.webp";
import byggkompletering9 from "../assets/byggkompletering/byggkompletering9.webp";
import byggkompletering10 from "../assets/byggkompletering/byggkompletering10.webp";

import hus1 from "../assets/hus/hus1.webp";
import hus2 from "../assets/hus/hus2.webp";
import hus3 from "../assets/hus/hus3.webp";
import hus5 from "../assets/hus/hus5.webp";
import hus6 from "../assets/hus/hus6.webp";
import fönsterdörr1 from "../assets/fönsterdörr/fönsterdörr1.webp";
import fönsterdörr2 from "../assets/fönsterdörr/fönsterdörr2.webp";
import fönsterdörr3 from "../assets/fönsterdörr/fönsterdörr3.webp";
import fönsterdörr4 from "../assets/fönsterdörr/fönsterdörr4.webp";
import fönsterdörr5 from "../assets/fönsterdörr/fönsterdörr5.webp";
import fönsterdörr6 from "../assets/fönsterdörr/fönsterdörr6.webp";
import fönsterdörr7 from "../assets/fönsterdörr/fönsterdörr7.webp";
import fönsterdörr8 from "../assets/fönsterdörr/fönsterdörr8.webp";
import fönsterdörr9 from "../assets/fönsterdörr/fönsterdörr9.webp";

export const navLinks = [
  { name: "Hem", path: "/" },
  { name: "Tjänster", path: "/services", hasDropdown: true },
  { name: "Om Oss", path: "/about" },
  { name: "Kontakt", path: "/contact" },
];

export const services = [
  {
    name: "Tak",
    path: "/services/roofing",
    images: [tak1, tak2, tak3, tak4, tak5, tak6],
    shortDescription: "Hållbar takläggning för skydd mot väder och vind.",
    longDescription:
      "Vi erbjuder hållbar takläggning som skyddar ditt hem från väder och vind. Våra experter ser till att ditt tak håller i många år framöver, med material av högsta kvalitet och noggrant utfört arbete.",
    moreInfo:
      "Vi arbetar med olika typer av takmaterial och erbjuder anpassade lösningar för varje kund. Oavsett om du behöver ett nytt tak eller reparation av det befintliga, kan vi hjälpa dig att hitta den bästa lösningen för ditt hem.",
  },
  {
    name: "Fasad",
    path: "/services/facade",
    images: [fasad1, fasad2, fasad3, fasad4, fasad6],
    shortDescription: "Renovera och förbättra din fasad.",
    longDescription:
      "Vår fasadrenoveringstjänst hjälper dig att modernisera och förbättra ditt hems utseende. Vi använder de bästa materialen för att säkerställa att din fasad inte bara ser bra ut utan också står emot tidens tand.",
    moreInfo:
      "Våra fasadrenoveringar inkluderar allt från mindre reparationer till fullständiga renoveringar. Vi arbetar med olika material som trä, sten, och puts för att matcha din vision och ditt hems stil.",
  },
  {
    name: "Fönster & dörr",
    path: "/services/windows-roof-installation",
    images: [
      fönsterdörr1,
      fönsterdörr2,
      fönsterdörr3,
      fönsterdörr4,
      fönsterdörr5,
      fönsterdörr6,
      fönsterdörr7,
      fönsterdörr8,
      fönsterdörr9,
    ],
    shortDescription: "Professionell installation av fönster och tak.",
    longDescription:
      "Vi erbjuder professionell installation av både fönster och tak för att säkerställa energieffektivitet och hållbarhet i ditt hem. Våra tjänster täcker allt från nyinstallationer till byten och reparationer.",
    moreInfo:
      "Våra installatörer har lång erfarenhet och arbetar med de senaste teknikerna för att säkerställa en säker och effektiv installation. Vi erbjuder även rådgivning om de bästa produkterna för ditt hem.",
  },
  {
    name: "Byggkompletering",
    path: "/services/construction-completion",
    images: [
      byggkompletering1,
      byggkompletering2,
      byggkompletering3,
      byggkompletering4,
      byggkompletering5,
      byggkompletering6,
      byggkompletering7,
      byggkompletering8,
      byggkompletering9,
      byggkompletering10,
    ],
    shortDescription: "Fullständig byggkompletering för ditt projekt.",
    longDescription:
      "Vi erbjuder kompletta byggkompletteringstjänster som säkerställer att ditt byggprojekt slutförs enligt plan och med högsta kvalitet. Vårt team hanterar allt från små detaljer till större konstruktioner.",
    moreInfo:
      "Med vår erfarenhet och expertis inom byggkompletering kan vi garantera att ditt projekt blir klart i tid och inom budget. Vi samarbetar med dig genom hela processen för att uppfylla dina specifika krav och säkerställa en högkvalitativ finish.",
  },
  {
    name: "Köksmontering",
    path: "/services/kitchen",
    images: [
      köksmontering1,
      köksmontering2,
      köksmontering3,
      köksmontering4,
      köksmontering5,
    ],
    shortDescription: "Professionell köksmontering för alla typer av kök.",
    longDescription:
      "Vi erbjuder en komplett lösning för köksmontering, från design och planering till installation och färdigställande. Vårt team har erfarenhet av att arbeta med alla typer av kök, från moderna till traditionella.",
    moreInfo:
      "Med fokus på detaljer och kvalitet säkerställer vi att ditt kök blir precis som du har föreställt dig. Vi använder de bästa materialen och teknikerna för att skapa ett kök som är både funktionellt och estetiskt tilltalande.",
  },
  {
    name: "Golvläggning",
    path: "/services/flooring",
    images: [golvläggning1, golvläggning2, golvläggning3, golvläggning4],
    shortDescription: "Professionell golvläggning för alla typer av golv.",
    longDescription:
      "Vi erbjuder golvläggningstjänster för alla typer av golv, inklusive trä, laminat, vinyl och kakel. Våra experter ser till att golvet installeras korrekt och håller länge.",
    moreInfo:
      "Oavsett om du bygger nytt eller renoverar, kan vi hjälpa dig att välja och installera det perfekta golvet för ditt hem. Vi erbjuder även golvunderhåll och reparationstjänster.",
  },
  {
    name: "Gips",
    path: "/services/drywall",
    images: [gibs1, gibs2, gibs3, gibs4, gibs5],
    shortDescription: "Expertinstallation av gips för väggar och tak.",
    longDescription:
      "Vi erbjuder installation av gips för både väggar och tak, vilket ger en slät och hållbar yta redo för målning eller tapetsering. Våra tjänster inkluderar även reparation av befintliga gipsskivor.",
    moreInfo:
      "Våra gipsexperter ser till att installationen görs snabbt och effektivt, med minimalt slöseri och maximal precision. Vi erbjuder även speciallösningar som ljud- och brandisolering.",
  },
  {
    name: "Bygga hus",
    path: "/services/home-construction",
    images: [hus2, hus1, hus3, hus5, hus6],
    shortDescription: "Vi bygger ditt drömhem från grund till tak.",
    longDescription:
      "Vårt byggteam tar hand om hela processen när du ska bygga ditt nya hem. Från planering och design till konstruktion och slutlig finish, vi ser till att ditt hem byggs enligt dina specifikationer och önskemål.",
    moreInfo:
      "Vi samarbetar med arkitekter och designers för att skapa ett hem som uppfyller dina drömmar. Vårt fokus ligger på kvalitet, hållbarhet och kundnöjdhet, och vi arbetar nära dig genom hela processen.",
  },
];
