import React from "react";
import Hero from "../components/Hero";
import ServiceCarousel from "../components/ServiceCarousel";
import IntroductionSection from "../components/IntroductionSection";

function Home() {
  return (
    <div>
      <Hero />
      <IntroductionSection />
      <ServiceCarousel />
    </div>
  );
}

export default Home;
